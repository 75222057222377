body {
  margin                  : 0;
  font-family             : 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing  : antialiased;
  -moz-osx-font-smoothing : grayscale;
  background-color        : #F0F2F1;
}

code {
  font-family : source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media (max-width : 575px) {
  body, html {
	font-size : 14px;
  }
}
